import { Icons } from "caspeco-casper-ui";
import { Dispatch, SetStateAction, useCallback } from "react";
import { useTranslation } from "react-i18next";
import ErrorTemplate from "./error-template";

type ServerNotReponseProps = {
    lastCall?: { fn: Function; args: unknown[] };
    setLastCall?: Dispatch<SetStateAction<{ fn: Function; args: unknown[] } | undefined>>;
};

export default function ServerDidNotRespond({ lastCall, setLastCall }: ServerNotReponseProps) {
    const { t } = useTranslation();

    const handleRetry = useCallback(() => {
        if (lastCall) {
            lastCall.fn(...lastCall.args);
        }
        // Optionally reset the lastCall state after retrying
        if (setLastCall) {
            setLastCall(undefined);
        }
    }, [lastCall, setLastCall]);

    return (
        <ErrorTemplate
            icon={Icons.Sweat}
            title={t("errorInformation.serverErrror")}
            message={t("errorInformation.serverLost")}
            btnCallback={handleRetry}
            btnText={t("errorInformation.tryAgain")}
        />
    );
}
