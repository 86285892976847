import { Box, Button, Flex, ThemeColorVariable, ThemeSpaceVariable } from "caspeco-casper-ui";
import { TimeSlot } from "hooks/api/types.d";

interface WaitListTimeSlotProps {
    time: TimeSlot;
    onTimeClick: (time: TimeSlot) => void;
    selectedTimes: TimeSlot[];
}

export default function WaitListTimeSlot({
    onTimeClick,
    time,
    selectedTimes,
}: WaitListTimeSlotProps) {
    const isSelected = selectedTimes.indexOf(time) !== -1;
    return (
        <Button
            key={time.start.format("HH:mm")}
            onClick={() => onTimeClick(time)}
            isSelected={isSelected}
            variant="card"
            size="lg"
            w="full"
            px={ThemeSpaceVariable.Small}
        >
            <Flex direction="column" align="center">
                <Box mt="6px">{time.start.format("HH:mm")}</Box>
                <Box
                    as="span"
                    w="6px"
                    h="6px"
                    background={ThemeColorVariable.Warning}
                    borderRadius="full"
                />
            </Flex>
        </Button>
    );
}
