import { usePaymentTerminal } from "api/api-hooks/payment-terminal";
import { Flex, Heading, ThemeSpaceVariable, useDisclosure } from "caspeco-casper-ui";
import BookingConditions from "components/details-form/booking-conditions";
import { PhoneField } from "components/details-form/phone-field";
import { PaymentMethodField } from "components/payment-field/payment-field";
import { PrivacyPolicyModal } from "components/privacy-policy-modal";
import { hasFlag } from "helpers";
import { getDefaultPaymentMethod } from "helpers/payment-helper";
import { useExternalBookingSettings } from "hooks";
import { useSelectedRule } from "hooks/use-selected-rule";
import { webbookingSettingFlags } from "old/models/unitMetaData";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ContactInfoForm, useBookingActionsContext, useBookingStateContext } from "store";
import { Contact } from "types";
import { PaymentRule, PaymentRule as paymentType } from "types/payment";
import WaitListNoShowAlert from "../wait-list/wait-list-no-show-alert";
import WaitListPrepaymentAlert from "../wait-list/wait-list-prepayment-alert";
import CustomerNameField from "./customer-name-field";
import EmailField from "./email-field";
import MessageField from "./message-field";
import NameField from "./name-field";
import NewsletterField from "./newsletter-field";
import NoShowAlert from "./no-show-alert";
import PrepaymentAlert from "./prepayment-alert";
import PrivacyPolicyText from "./privacy-policy-text";
import SubmitButton from "./submit-button";

export const DetailsWithPaymentForm = () => {
    const { t } = useTranslation();
    const { isOpen, onClose, onOpen } = useDisclosure();
    const { data: settings } = useExternalBookingSettings();
    const state = useBookingStateContext();
    const { handleFinalize, finalize } = useBookingActionsContext();
    const selectedRule = useSelectedRule();
    const terminal = usePaymentTerminal();

    const contact = (state.contact || {}) as Contact;

    const { control, handleSubmit, formState, trigger, watch } = useForm<ContactInfoForm>({
        mode: "onBlur",
        defaultValues: {
            ...contact,
            payMethod: getDefaultPaymentMethod(
                settings,
                selectedRule.paymentRule === PaymentRule.Prepayment,
                state
            ),
        },
    });

    const displayMessageBox = settings && settings.displayMessageBox;
    const unitMetaData = settings.unitsMetaData;
    const showCustomerNameField = hasFlag(
        unitMetaData.binaryFlags,
        webbookingSettingFlags.displayCustomerName
    );

    const isPrepayment = selectedRule?.paymentRule === paymentType.Prepayment;
    const isNoShow = selectedRule?.paymentRule === paymentType.NoShowFee;
    const onSubmit = () => {
        if (state.isWaitList || selectedRule.paymentRule === paymentType.NoFee) {
            handleFinalize();
        } else {
            const terminalState = {
                ...state,
                payment: {
                    payMethod: watch("payMethod"),
                },
            };
            terminal.mutate({ state: terminalState, isNoShow });
        }
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Flex direction="column" gap={ThemeSpaceVariable.Large}>
                <Flex
                    direction="column"
                    gap={ThemeSpaceVariable.Medium}
                    mt={ThemeSpaceVariable.Large}
                >
                    <BookingConditions />
                    <Heading as="h5" fontWeight="500">
                        {t("contactDetails")}
                    </Heading>
                    <NameField
                        value={contact.fname || ""}
                        control={control}
                        variant="fname"
                        // disabled={!!contactLoadedByFriendlyId?.fname}
                        trigger={trigger}
                    />
                    <NameField
                        value={contact.lname || ""}
                        control={control}
                        variant="lname"
                        // disabled={!!contactLoadedByFriendlyId?.lname}
                        trigger={trigger}
                    />
                    <PhoneField
                        value={contact.mobile || ""}
                        control={control}
                        // disabled={!!contactLoadedByFriendlyId?.mobile}
                        trigger={trigger}
                    />
                    <EmailField
                        value={contact.email || ""}
                        control={control}
                        // disabled={!!contactLoadedByFriendlyId?.email}
                        trigger={trigger}
                    />

                    {showCustomerNameField && <CustomerNameField control={control} />}

                    {displayMessageBox && (
                        <MessageField control={control} value={contact.message || ""} />
                    )}

                    {settings.newsletter && <NewsletterField />}
                </Flex>

                {isNoShow && (state.isWaitList ? <WaitListNoShowAlert /> : <NoShowAlert />)}
                {isPrepayment &&
                    (state.isWaitList ? <WaitListPrepaymentAlert /> : <PrepaymentAlert />)}

                {selectedRule?.paymentRule === paymentType.Prepayment && !state.isWaitList && (
                    <PaymentMethodField control={control} />
                )}

                <Flex direction="column" gap={ThemeSpaceVariable.Medium}>
                    <SubmitButton
                        isLoading={finalize.isPending || terminal?.isPending}
                        isDisabled={!formState.isValid}
                    />
                    <PrivacyPolicyText onOpen={onOpen} />
                </Flex>
            </Flex>
            <PrivacyPolicyModal isOpen={isOpen} onClose={onClose} />
        </form>
    );
};
