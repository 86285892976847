import { useWebBooking } from "api/api-hooks";
import NoShowChargeAlert from "components/alerts/no-show-charge-alert";
import NoShowAlert from "components/details-form/no-show-alert";
import PrepaymentAlert from "components/details-form/prepayment-alert";
import WaitListNoShowAlert from "components/wait-list/wait-list-no-show-alert";
import WaitListPrepaymentAlert from "components/wait-list/wait-list-prepayment-alert";
import { PaymentRule } from "types/payment";
import { isNoShowBooking, isWaitList } from "utils/booking";

interface InfromationAlertSectionProps {
    shouldShowConfirmWaitListBtn: boolean;
    isBookingCanceled: boolean;
}

const MyBookingAlert = ({
    shouldShowConfirmWaitListBtn,
    isBookingCanceled,
}: InfromationAlertSectionProps) => {
    const { data: booking } = useWebBooking();
    const isNoShow = isNoShowBooking(booking);
    const isWaitListBooking = isWaitList(booking);

    const rule = booking.webBookingRules?.at(0);
    const isPrePayment = rule?.paymentRule === PaymentRule.Prepayment;
    const isNoShowFee = rule?.paymentRule === PaymentRule.NoShowFee;

    const hasNoShowCharge = booking.paymentEvents?.at(-1)?.payedAmount > 0 && isNoShow;

    if (hasNoShowCharge) {
        return <NoShowChargeAlert />;
    }

    if (isNoShowFee && isWaitListBooking && !shouldShowConfirmWaitListBtn && !isBookingCanceled) {
        return <WaitListNoShowAlert />;
    }

    if (isPrePayment && isWaitListBooking && !shouldShowConfirmWaitListBtn && !isBookingCanceled) {
        return <WaitListPrepaymentAlert />;
    }

    if (isNoShowFee && shouldShowConfirmWaitListBtn) {
        return <NoShowAlert />;
    }

    if (isPrePayment && shouldShowConfirmWaitListBtn) {
        return <PrepaymentAlert />;
    }

    return null;
};

export default MyBookingAlert;
