import { parseBackendData } from "api/backend-data-parser";
import axios from "axios";
import config from "config/app.config";
import { getSearchParams } from "search-params";
const { system } = getSearchParams();

const axiosInstance = axios.create({
    baseURL: `${config.baseUrl}/v1`,
    headers: { system: system },
});

export enum HttpStatusCode {
    OK = 200,
    BadRequest = 400,
    NotFound = 404,
    NoAuth = 403,
    InternalServerError = 500,
    GatewayTimeout = 504,
}

export async function get<T extends Object>(input: string) {
    const response = await axiosInstance.get(input);
    return response.data as T;
}

export async function genericGet<T extends Object>(input: string): Promise<T | null> {
    try {
        const response = await axiosInstance.get(input);
        return parseBackendData(response.data) as T;
    } catch (error) {
        console.error(error);
    }
}

export async function post<TResponse extends Object, TBody>(input: string, body: TBody) {
    const response = await axiosInstance.post(input, body);
    return response.data as TResponse;
}
