import { DetailsWithPaymentForm } from "components/selection-components";
import Spinner from "components/spinner/spinner";
import { useAvailableTimes } from "api/api-hooks";
import { useBookingStateContext } from "store";

const DetailsFormWrapper = () => {
    const state = useBookingStateContext();
    const availableTimes = useAvailableTimes(state.date);

    // TODO: Build skeleton loader for form
    // if (availableTimes.isPending) return <DetailsAndPaymentFormSkeleton />;
    if (availableTimes.isPending) return <Spinner />;

    return <DetailsWithPaymentForm />;
};

export default DetailsFormWrapper;
