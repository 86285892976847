import { Heading, Stack, Text, ThemeFontSizeVariable, ThemeSpaceVariable } from "caspeco-casper-ui";
import StatusFailedIcon from "components/booking-status/status-failed-icon";

const StatusFailedWithDialogue = ({
    title,
    message,
}: {
    title: string;
    message: string | JSX.Element;
}) => {
    return (
        <Stack alignItems="center" spacing={ThemeSpaceVariable.Medium}>
            <StatusFailedIcon />
            <Stack spacing="none">
                <Heading as="h1" fontWeight="500" textAlign="center" mb={ThemeSpaceVariable.Small}>
                    {title}
                </Heading>
                <Text maxW="496" textAlign="center" fontSize={ThemeFontSizeVariable.Large}>
                    {message}
                </Text>
            </Stack>
        </Stack>
    );
};

export default StatusFailedWithDialogue;
