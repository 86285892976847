export enum BookingStatus {
    Unset = -1,
    History = 4,
    Canceled = 7,
    WaitList = 9,
    Web = 100,
    Paid = 108,
    Left = 109,
    NoShow = 110,

    BookingInProgress = 112,
    Expired = 113,
    PaymentFailed = 114,
    PaymentRefundFailed = 115,
}
