import { InputGroup } from "@chakra-ui/react";
import {
    Alert,
    Box,
    Button,
    FormControl,
    Grid,
    Icons,
    ThemeSpaceVariable,
    Heading,
    Text,
    ThemeFontSizeVariable,
} from "caspeco-casper-ui";
import { SafeHTML } from "components/safe-HTML";
import { useExternalBookingSettings } from "api/api-hooks";
import { trackEvent } from "logging/insights";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useBookingActionsContext, useBookingStateContext } from "store";
import SelectionHeader from "./selection-header";

function trackLoadingTime() {
    if ("__APP_START_TIME__" in window && "__IS_TRACKED__" in window && !window.__IS_TRACKED__) {
        window.__IS_TRACKED__ = true;
        const now = new Date();
        const loadTime = now.getTime() - window.__APP_START_TIME__.getTime();
        console.log("App load time: ", loadTime);
        trackEvent("AppLoadTime", {
            ms: loadTime,
        });
    }
}
export const SelectGuestsAmount = () => {
    const state = useBookingStateContext();
    const { setGuests } = useBookingActionsContext();
    const { t } = useTranslation();

    const { guests } = state;

    const externalBookingSettings = useExternalBookingSettings().data;
    const { maxWebGuests } = externalBookingSettings.unitsMetaData;

    const introductionMessageHeader = externalBookingSettings.introductionMessageHeader;
    const introductionMessage = externalBookingSettings.introductionMessage;

    useEffect(trackLoadingTime, []);

    const mobileColumns = "repeat(3, 1fr)";
    const smallScreenColumns = "repeat(4, 1fr)";
    return (
        <FormControl key="guest-choice" id="guestChoice">
            {introductionMessageHeader && (
                <Heading as="h1" fontSize={ThemeFontSizeVariable.X2Large}>
                    {introductionMessageHeader}
                </Heading>
            )}
            {introductionMessage && (
                <Text mt={ThemeSpaceVariable.Medium} mb={ThemeSpaceVariable.Large}>
                    {introductionMessage}
                </Text>
            )}

            <SelectionHeader id="guests" icon={Icons.UserGroup} text={t("start.pickGuestsHint")} />
            <InputGroup>
                <Grid
                    templateColumns={{
                        base: mobileColumns,
                        sm: smallScreenColumns,
                    }}
                    gap={ThemeSpaceVariable.Small}
                    width="100%"
                >
                    {[...Array(maxWebGuests + 1).keys()]
                        .map((n) => n + 1)
                        .map((guests) => {
                            return (
                                <Button
                                    key={guests}
                                    variant="card"
                                    size="lg"
                                    display="flex"
                                    w="100%"
                                    alignItems="center"
                                    justifyContent="center"
                                    onClick={() => {
                                        setGuests(guests);
                                    }}
                                >
                                    {guests}
                                    {guests === maxWebGuests + 1 && "+"}
                                </Button>
                            );
                        })}
                </Grid>
            </InputGroup>
            {guests >= maxWebGuests && (
                <Box mt={ThemeSpaceVariable.Medium}>
                    <Alert type="info" iconVerticalAlign={"flex-start"}>
                        <SafeHTML
                            className="textFromSettings"
                            element="div"
                            options={{
                                allowedTags: ["a", "br"],
                                allowedSchemes: ["tel", "mailto", "https"],
                            }}
                            html={externalBookingSettings.unitsMetaData.messageExceededGuests}
                        />
                    </Alert>
                </Box>
            )}
        </FormControl>
    );
};
