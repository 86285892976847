import { useWebBooking } from "hooks";
import { useMemo } from "react";
import { useBookingStateContext } from "store";
import { useAvailableTimes } from "hooks";
import { AvailableTimesSection, AvailableTimesUnit, WebRule } from "types";

export const useSelectedRule = (ruleIdOverride?: number) => {
    const state = useBookingStateContext();
    const availableTimes = useAvailableTimes(state.date);
    const { data: webBooking } = useWebBooking();

    const ruleId = (state.times && state.times[0]?.ruleId) || ruleIdOverride;

    let selectedRule = useMemo(() => {
        return availableTimes?.data
            ?.flatMap((item: AvailableTimesUnit) =>
                item.sections.flatMap((section: AvailableTimesSection) => section.timeSets)
            )
            .find((timeSet: WebRule) => timeSet.id === ruleId) as WebRule;
    }, [availableTimes?.data, ruleId]);

    if (webBooking?.webBookingRules) selectedRule = webBooking.webBookingRules.at(0);

    return selectedRule;
};
