import { Box } from "caspeco-casper-ui";
import DetailsFormWrapper from "components/details-form/details-form-wrapper";
import ErrorBoundary from "components/error-boundary/ErrorBoundary";
import ReservationFailedModal from "components/error-components/reservation-failed-modal";
import {
    EditSelectionButtonGroup,
    SelectChildrenAmount,
    SelectDate,
    SelectGuestsAmount,
    SelectTime,
} from "components/selection-components";
import Spinner from "components/spinner/spinner";
import { useError } from "context/error-context";
import { useDayStates, useExternalBookingSettings } from "api/api-hooks";
import { IsoCountryCode } from "old/application/models/management/user";
import { ErrorView } from "pages/error-view";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getSearchParams } from "search-params";
import { useBookingStateContext } from "../store";

import "global.less";

const isInitialized = false;
async function init() {
    if (isInitialized) return;

    const { system } = getSearchParams();

    const systemParts = system?.split("_");
    const [SYSTEM_PREFIX] = systemParts ?? [];

    if (
        SYSTEM_PREFIX &&
        !Object.values(IsoCountryCode).includes(SYSTEM_PREFIX.toLocaleUpperCase())
    ) {
        // Antiviruses scanning emails, ROT-13 encrypts system- and other GET-parameters resulting in spammy requests to MARC
        throw new Error(`Invalid system prefix in: ${system}`);
    }
}
init();
export default function BookingWizard() {
    const externalBookingSettings = useExternalBookingSettings();
    const { system, unitId } = useParams();
    const { error, isLoading } = useError();

    const [selectedMonth, setSelectedMonth] = useState(new Date());

    useDayStates(selectedMonth);

    useEffect(() => {
        if (system && unitId) {
            localStorage.setItem("lastKnownSystemId", system);
            localStorage.setItem("lastKnownUnitId", unitId);
        }
    }, [system, unitId]);

    const { guests, guestsChildren, date, times } = useBookingStateContext();

    if (isLoading || externalBookingSettings.isLoading) {
        return <Spinner />;
    }
    if (error || externalBookingSettings.isError) {
        return <ErrorView />;
    }
    const { activateWebChildren, maxWebGuests } = externalBookingSettings.data.unitsMetaData;

    return (
        <ErrorBoundary fallback={<ErrorView />}>
            <Box className="webBookingContainer" flex={1}>
                <ReservationFailedModal />
                <EditSelectionButtonGroup />
                {!guests || guests > maxWebGuests ? (
                    <SelectGuestsAmount />
                ) : activateWebChildren && guestsChildren === null ? (
                    <SelectChildrenAmount />
                ) : !date ? (
                    <SelectDate selectedMonth={selectedMonth} setSelectedMonth={setSelectedMonth} />
                ) : !times ? (
                    <SelectTime />
                ) : (
                    <DetailsFormWrapper />
                )}
            </Box>
        </ErrorBoundary>
    );
}
