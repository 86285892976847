import i18n from "config/i18n";

export enum SupportedLanguage {
    EN = "en",
    SV = "sv",
    NB = "nb",
    DA = "da",
    FI = "fi",
    ES = "es",
    // ET = "et-EE",
    // RU = "ru-RU",
    // FR = "fr-BE",
    // NL = "nl-BE",
}

export enum SupportedLocale {
    SV = "sv-SE",
    EN = "en-GB",
    NB = "nb-NO",
    DA = "da-DK",
    FI = "fi-FI",
    ES = "es-ES",
}

const langFromLocale = (lang: string): string => lang.split("-")[0].toLowerCase();
/**
 * Will return the locale limited to what the date picker supports.
 */
export const getDatePickerLocale = (locale: string): SupportedLocale => {
    const lang = langFromLocale(locale);
    switch (lang) {
        case SupportedLanguage.EN:
            return SupportedLocale.EN;
        case SupportedLanguage.NB:
            return SupportedLocale.NB;
        case SupportedLanguage.DA:
            return SupportedLocale.DA;
        case SupportedLanguage.SV:
            return SupportedLocale.SV;
        default:
            return SupportedLocale.EN;
    }
};

/**
 * Will return the locale based on the language.
 */
export const getLocaleFromLang = (locale: string): SupportedLocale => {
    const lang = langFromLocale(locale);
    switch (lang) {
        case SupportedLanguage.EN:
            return SupportedLocale.EN;
        case SupportedLanguage.NB:
            return SupportedLocale.NB;
        case SupportedLanguage.DA:
            return SupportedLocale.DA;
        case SupportedLanguage.SV:
            return SupportedLocale.SV;
        case SupportedLanguage.FI:
            return SupportedLocale.FI;
        case SupportedLanguage.ES:
            return SupportedLocale.ES;
        default:
            return SupportedLocale.EN;
    }
};

/**
 * Helper to get translated name of current used i18n language.
 * @returns translated name of current language.
 * For ex. i18n language is "SV" the return value will be "Svenska".
 */
export const getNameOfCurrentLang = () => {
    switch (i18n.language) {
        case SupportedLanguage.EN:
            return i18n.t("miscEnglish");
        case SupportedLanguage.SV:
            return i18n.t("miscSwedish");
        case SupportedLanguage.NB:
            return i18n.t("miscNorwegian");
        case SupportedLanguage.DA:
            return i18n.t("miscDanish");
        case SupportedLanguage.FI:
            return i18n.t("miscFinnish");
        case SupportedLanguage.ES:
            return i18n.t("miscEspanol");
        default:
            return i18n.t("miscEnglish");
    }
};

export default getNameOfCurrentLang;

//#newcountry Update needed here when adding a new country
export type LocaleIdString =
    | "da-DK"
    | "sv-SE"
    | "en-GB"
    | "en-SE"
    | "en-US"
    | "nb-NO"
    | "no-NO"
    | "nn-NO"
    | "et-EE"
    | "nl-BE"
    | "fr-BE"
    | "ru-RU"
    | "fi-FI"
    | "nl-NL"
    | "es-ES";

export function formatTargetLanguage(targetLanguage: string): LocaleIdString {
    // Format the last two letters to uppercase to match LocaleIdString-format
    const formatted = <LocaleIdString>(
        (targetLanguage.substring(0, 3) + targetLanguage.substring(3, 5).toUpperCase())
    );
    return formatted;
}
