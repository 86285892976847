import { usePaymentTerminalMyBooking } from "api/api-hooks/payment-terminal";
import { Button, Stack, Text, ThemeColorVariable, ThemeSpaceVariable } from "caspeco-casper-ui";
import { ConfirmPaymentForm, PaymentMethodField } from "components/payment-field/payment-field";
import { getDefaultPaymentMethod } from "helpers/payment-helper";
import { useConfirmWaitListBooking, useExternalBookingSettings, useWebBooking } from "hooks";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { getSearchParams } from "search-params";
import { useBookingStateContext } from "store";
import { PaymentRule } from "types";
import {
    hasUnhandledNoShowLink,
    hasUnhandledPaymentEventLink,
    hasUnpaidPrepayment,
    isNoShowLink,
    isPaymentEventExpired,
    isWaitList,
} from "utils/booking";

const BookingActionButton = () => {
    const { t } = useTranslation();
    const { data: webBooking } = useWebBooking();
    const { mutate: confirmMutate, isPending: confirmIsLoading } = useConfirmWaitListBooking();
    const paymentTerminal = usePaymentTerminalMyBooking();
    const { isPaymentLink } = getSearchParams();

    const state = useBookingStateContext();

    const { data: settings } = useExternalBookingSettings();

    const rule = webBooking?.webBookingRules.at(0);
    const isPrepayment =
        (rule && rule.paymentRule === PaymentRule.Prepayment) || hasUnpaidPrepayment(webBooking);

    const { control, watch } = useForm<ConfirmPaymentForm>({
        mode: "onChange",
        defaultValues: {
            payMethod: getDefaultPaymentMethod(settings, isPrepayment, state),
        },
        reValidateMode: "onChange",
    });

    const availablePaymentMethods = settings?.paymentMethods;

    const hasLinkPassed = isPaymentEventExpired(webBooking, isPaymentLink);

    const requestedAmount = webBooking.paymentEvents.at(-1)?.requestedAmount;
    const cardRegistration =
        (rule && rule.paymentRule === PaymentRule.NoShowFee) || hasUnhandledNoShowLink(webBooking);

    const getButtonText = () => {
        if (isPrepayment) {
            return t("payAmount", {
                amount: t("currency.onlyAmount", {
                    value: requestedAmount,
                }),
            });
        }
        if (cardRegistration) {
            return t("registerPaymentCard");
        }
        return t("waitList.confirmBooking");
    };

    const isWaitListBooking = isWaitList(webBooking);
    const onActionClick = () => {
        const isPaymentLinkWithUnhandledPaymentEvent =
            hasUnhandledPaymentEventLink(webBooking, isPaymentLink) && !isWaitListBooking;
        const isWaitListWithPaymentRule = isWaitListBooking && rule.hasPaymentRule();

        if (isPaymentLinkWithUnhandledPaymentEvent || isWaitListWithPaymentRule) {
            paymentTerminal.mutate({
                state: { payment: { payMethod: watch("payMethod") } },
                isPaymentLink: isPaymentLinkWithUnhandledPaymentEvent,
                isWaitList: isWaitListWithPaymentRule,
            });
        } else {
            confirmMutate();
        }
    };

    const isNoShowPaymentLink = isNoShowLink(webBooking);
    const linkExpirationDate =
        webBooking.chargePaymentLinkExpirationDate?.format("YYYY-MM-DD HH:mm");
    const isPaymentMethodFieldVisible = availablePaymentMethods.length > 1 && isPrepayment;
    return (
        <>
            {isPaymentMethodFieldVisible && <PaymentMethodField control={control} />}
            <Stack spacing={ThemeSpaceVariable.Small}>
                <Button
                    variant="primary"
                    isLoading={confirmIsLoading || paymentTerminal.isPending}
                    onClick={onActionClick}
                    isDisabled={hasLinkPassed}
                    size="lg"
                >
                    {getButtonText()}
                </Button>
                {hasUnhandledPaymentEventLink(webBooking, isPaymentLink) &&
                    !isWaitListBooking &&
                    linkExpirationDate && (
                        <Text align="center" color={ThemeColorVariable.OnSurfaceSubdued}>
                            {isNoShowPaymentLink
                                ? t("payment.cardRegistrationPossibleTo", {
                                      date: linkExpirationDate,
                                  })
                                : t("payment.prepaymentPossibleTo", { date: linkExpirationDate })}
                        </Text>
                    )}
            </Stack>
        </>
    );
};

export default BookingActionButton;
