import moment from "moment";
import { State } from "store";
import { UrlParams } from "utils";

const parseTimes = (params: UrlParams) => {
    return params.end
        ? [
              {
                  ruleId: params.ruleId,
                  start: moment(params.start, "HHmm").set({
                      date: params.date.date(),
                      month: params.date.month(),
                      year: params.date.year(),
                  }),
                  end: moment(params.end, "HHmm").set({
                      date: params.date.date(),
                      month: params.date.month(),
                      year: params.date.year(),
                  }),
              },
          ]
        : params.start
          ? params.start.split("-").map((t) => ({
                ruleId: params.ruleId,
                start: moment(t, "HHmm").set({
                    date: params.date?.date(),
                    month: params.date?.month(),
                    year: params.date?.year(),
                }),
            }))
          : null;
};

export const getStateFromParams = (params: UrlParams, previousState: State) => {
    const state: State = previousState;
    state.guests = params.guests;
    state.guestsChildren = params.guestsChildren;
    state.date = params.date;
    state.times = parseTimes(params);
    return state;
};
