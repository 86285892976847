const CURRENCY_MAP = {
    no: "NOK",
    us: "USD",
    se: "SEK",
    dk: "DKK",
    fi: "EUR",
    be: "EUR",
    ee: "EUR",
    ru: "RUB",
    nl: "EUR",
    es: "EUR",
};

export const getCurrency = (system: string) => {
    if (system?.trim().length === 0) {
        return "SEK";
    }

    const prefix = system?.split("_")[0];
    return CURRENCY_MAP[prefix as keyof typeof CURRENCY_MAP] || "SEK";
};

export const setCurrencyDisplay = (
    display: "name" | "symbol" | "narrowSymbol" | "code" | "none"
) => {
    if (display === "none") return "code";
    return display;
};

export enum CurrencyDisplay {
    Symbol = "symbol",
    NarrowSymbol = "narrowSymbol",
    Name = "name",
    Code = "code",
    None = "none",
}
