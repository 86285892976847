import { ScaleFade } from "@chakra-ui/react";
import { Button, ThemeSpaceVariable, Tooltip } from "caspeco-casper-ui";
import { useAvailableTimes } from "api/api-hooks";
import { TimeSlot as TimeSlotType, WebRule, WebRuleBookingType } from "types";

import { useTranslation } from "react-i18next";
import { useBookingActionsContext, useBookingStateContext } from "store";

interface TimeSlotProps {
    time: TimeSlotType;
    timeSet: WebRule;
}

export default function TimeSlot(props: TimeSlotProps) {
    const { t } = useTranslation();

    const state = useBookingStateContext();

    const { date, guests, times } = state;

    const { handleReservation } = useBookingActionsContext();
    const units = useAvailableTimes(date).data;

    const handleClick = async () => {
        const newTime = { ruleId: time.ruleId, start: time.start, end: time.end };

        const stateWithTimes = {
            ...state,
            times: state.times ? [...state.times, newTime] : [newTime],
        };

        handleReservation(stateWithTimes);
    };

    const allTimeSets: { [key: number]: WebRule } = {};
    units.forEach((unit) => {
        unit.sections.forEach((section) => {
            section.timeSets.forEach((timeSet) => {
                allTimeSets[timeSet.id] = timeSet;
            });
        });
    });

    units.flatMap((unit: any) =>
        unit.sections.flatMap((section: any) =>
            section.timeSets.map((timeSet: any) => [timeSet.id, timeSet])
        )
    );

    const isOtherRuleTimeOverlapping = () => {
        const { time, timeSet } = props;
        return !!times?.find((x) => {
            if (x.ruleId === time.ruleId) return false; // Skip times in same rules
            // const xTimeSet = allTimeSets[x.rule];

            return x.start.isBefore(time.end.clone().subtract(timeSet.recoupTime, "minutes"));
            //      && time.start.isBefore(x.end.clone().subtract(xTimeSet.recoupTime, "minutes"))
        });
    };

    const showEndTime = (time: TimeSlotType) => {
        return allTimeSets[time.ruleId].bookingType === WebRuleBookingType.Delivery;
    };

    const { time } = props;
    const isSelected = !!times?.find(
        (t) => time.ruleId === t.ruleId && time.start.isSame(t.start, "day")
    );
    /*
    const shouldDisableTime =
        times.length === 1 &&
        ((times[0].first()?.isWaitList && time.sectionId !== selectedTimes.first()?.sectionId) ||
            (!selectedTimes.first()?.isWaitList && time.isWaitList));
*/
    const disabled =
        !(time.availableSeats.includes(guests) || time.waitListSeats.includes(guests)) ||
        isOtherRuleTimeOverlapping();
    // || shouldDisableTime;

    const relevantUnavailability =
        time.unavailability &&
        time.unavailability.find((x) => {
            return x?.from <= guests && x?.to >= guests;
        });
    const tooltip = relevantUnavailability
        ? t(`unavailabilityReasonEnum.${relevantUnavailability.reason}`)
        : t("noBookableResourcesForGroupSize");

    return (
        <Tooltip key={time.start.format("HH:mm")} title={time.disabled ? tooltip : ""}>
            <ScaleFade initialScale={0.9} in={true} transition={{ enter: { duration: 0.5 } }}>
                <Button
                    key={time.start.format("HH:mm")}
                    className="timeButton"
                    isDisabled={disabled}
                    onClick={handleClick}
                    isSelected={isSelected}
                    variant="card"
                    w="full"
                    px={ThemeSpaceVariable.Small}
                    size="lg"
                >
                    {time.start.format("HH:mm") +
                        (showEndTime(time) ? ` - ${time.end.format("HH:mm")}` : "")}{" "}
                </Button>
            </ScaleFade>
        </Tooltip>
    );
}
