import { useWebBooking } from "api/api-hooks";
import {
    Box,
    Flex,
    Text,
    ThemeBorderVariable,
    ThemeColorVariable,
    ThemeRadiusVariable,
    ThemeSpaceVariable,
} from "caspeco-casper-ui";
import { useAvailableTimes } from "api/api-hooks";
import { SpaceBetween } from "layout/space-between";
import { BookingSummaryItem } from "pages/booking-details/booking-summary/booking-summary-list-item";
import { useTranslation } from "react-i18next";
import { useBookingStateContext } from "store";
import { getUniqueContainerArticlesByTimeAndGroup } from "utils";
import { getAvailableWaitListOffer, hasAvailableWaitListOffers, isWaitList } from "utils/booking";
import { getRuleById } from "utils/time-rule-utils";
import BookingMessage from "./booking-message";
import CardRegistrationInformation from "./card-registration-information";
import PrepaymentInformation from "./prepayment-information";
import { ChargeType } from "types";

export const BookingSummary = () => {
    const { t } = useTranslation();
    const { data: webBooking } = useWebBooking();
    const { date, times } = useBookingStateContext();
    const availableTimes = useAvailableTimes(date);

    const hasAvailableOffers = hasAvailableWaitListOffers(webBooking);
    const isWaitListBooking = isWaitList(webBooking);

    const contactName = `${webBooking?.contact.fname} ${webBooking?.contact.lname}`;
    const startTimes = webBooking?.articles
        .filter((art) => art.isTable)
        .sort((a, b) => a.start.valueOf() - b.start.valueOf())
        .map((time) => time.start.format("HH:mm"))
        .join(", ");

    const uniqueResources = getUniqueContainerArticlesByTimeAndGroup(webBooking);
    let filteredResources = uniqueResources;
    if (hasAvailableOffers) {
        const offer = getAvailableWaitListOffer(webBooking).startTime;
        filteredResources = uniqueResources.filter((article) => article.start.isSame(offer));
    }

    const showWaitListSummary = isWaitListBooking && !hasAvailableOffers;

    const ruleById = getRuleById(times ? times[0]?.ruleId : -1, availableTimes.data);

    const ruleName = ruleById
        ? ruleById.title.toLowerCase()
        : webBooking.webBookingRules?.at(0)?.title.toLowerCase();

    const border = `${ThemeBorderVariable.XSmall} ${ThemeColorVariable.OnSurfaceBorder}`;
    return (
        <Flex
            direction="column"
            bg={ThemeColorVariable.Surface}
            p={ThemeSpaceVariable.Medium}
            border={border}
            borderRadius={ThemeRadiusVariable.Medium}
            gap={ThemeSpaceVariable.Medium}
        >
            {showWaitListSummary ? (
                <>
                    <Text fontWeight="medium">
                        {t("waitList.waitListFor", {
                            webTimeRuleName: ruleName,
                        })}
                    </Text>
                    <BookingSummaryItem label={t("startTime")} value={startTimes} />
                </>
            ) : (
                <>
                    <SpaceBetween>
                        <Text fontWeight="500">{t("summary.globalBookingNumber")}</Text>
                        <Text fontWeight="500">{webBooking?.globalBookingNumber}</Text>
                    </SpaceBetween>
                    <Box>
                        <Text fontWeight="medium">{t("bookingDetails")}</Text>
                        <Flex
                            gap={ThemeSpaceVariable.XSmall}
                            mt={ThemeSpaceVariable.Small}
                            direction="column"
                        >
                            {filteredResources.map((article) => {
                                return (
                                    <BookingSummaryItem
                                        key={article.start.toString()}
                                        label={article.articleGroupName}
                                        value={`${article.start.format("HH:mm")}`}
                                    />
                                );
                            })}
                        </Flex>
                    </Box>
                </>
            )}

            <Box>
                <Text fontWeight="medium">{t("guestInfromation")}</Text>
                <Flex
                    direction="column"
                    gap={ThemeSpaceVariable.XSmall}
                    mt={ThemeSpaceVariable.Small}
                    alignItems="start"
                >
                    {webBooking.customer?.name && (
                        <Text color={ThemeColorVariable.OnSurfaceSubdued}>
                            {webBooking?.customer.name}
                        </Text>
                    )}
                    <Text color={ThemeColorVariable.OnSurfaceSubdued}>{contactName}</Text>
                    <Text color={ThemeColorVariable.OnSurfaceSubdued}>
                        {webBooking.contact?.email}
                    </Text>
                    {
                        <Text color={ThemeColorVariable.OnSurfaceSubdued}>
                            {webBooking.contact?.mobile}
                        </Text>
                    }
                </Flex>
            </Box>
            {!!webBooking?.message && <BookingMessage message={webBooking?.message} />}
            {webBooking.chargeType === ChargeType.NoShow && <CardRegistrationInformation />}
            {webBooking.chargeType === ChargeType.PrePayment && <PrepaymentInformation />}
        </Flex>
    );
};
