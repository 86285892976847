import { parseDate } from "utils";

export enum BookedStatus {
    Undefined = 0,
    Available = 1,
    WaitList = 2,
    FullyBooked = 3,
}

export enum DayStateStatus {
    Undefined = 0,
    Closed = 1,
    Open = 3,
}

export class DayState {
    day: IMoment;
    unitId: number;
    sectionId: number;
    state: DayStateStatus;
    availableSeats?: number[];
    waitListSeats?: number[];
    bookedStatus: BookedStatus;

    constructor(values?: DayState) {
        this.day = parseDate(values.day).local();
        this.unitId = values?.unitId ?? 0;
        this.sectionId = values?.sectionId ?? 0;
        this.state = values?.state ?? DayStateStatus.Undefined;
        this.availableSeats = values?.availableSeats ?? null;
        this.waitListSeats = values?.waitListSeats ?? null;
        this.bookedStatus = values?.bookedStatus ?? BookedStatus.Undefined;
    }
}
