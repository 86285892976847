import { useExternalBookingSettings } from "hooks";
import { useSelectedRule } from "./use-selected-rule";

const useWebConditions = () => {
    const settings = useExternalBookingSettings().data;
    const selectedRule = useSelectedRule();

    const conditions: string[] = [];
    if (settings?.webConditions) {
        conditions.push(settings.webConditions);
    }

    if (selectedRule?.conditions) {
        conditions.push(selectedRule.conditions);
    }

    return conditions;
};

export default useWebConditions;
