import moment, { Moment } from "moment";

export const dateToString = (date: Moment) => `${date?.format("YYYY-MM-DD")}T00:00:00.000Z`;

export const dateTimeToString = (dateTime: Moment) =>
    `${dateTime?.format("YYYY-MM-DDTHH:mm:ss")}.000Z`;

/**
 * Parses a date string in the format "YYYY-MM-DDTHH:mm:ss".
 * This function does NOT handle timezone information in the input string (e.g., "+01:00").
 *
 * @param {Moment | undefined} date - The date string to parse.
 * @returns {Moment} - A valid Moment object if the date is valid, otherwise an invalid Moment object.
 */
export const parseDate = (date?: Moment): Moment => {
    return moment(date, "YYYY-MM-DDTHH:mm:ss", true).isValid()
        ? moment(date, "YYYY-MM-DDTHH:mm:ss")
        : moment.invalid();
};

/**
 * Parses a date string in ISO 8601 format.
 * This function can handle timezone information in the input string (e.g., "+01:00").
 *
 * @param {Moment | string | undefined} date - The date string to parse.
 * @returns {Moment} - A valid Moment object if the date is valid, otherwise an invalid Moment object.
 */
export const parseDateWithTimeZone = (date?: Moment | string, nullable?: boolean): Moment => {
    return moment(date, moment.ISO_8601, true).isValid()
        ? moment(date)
        : nullable
          ? null
          : moment.invalid();
};
