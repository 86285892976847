import { useMutation } from "@tanstack/react-query";
import { post } from "api/api-methods";
import i18n from "config/i18n";
import { useError } from "context/error-context";
import { CreateReservationParameters, WebBooking } from "types";
import { trackEvent } from "logging/insights";
import queryClient from "query-client";
import { useNavigate, useParams } from "react-router-dom";
import { GUID_STORAGE_KEY, State } from "store";
import { getLocaleFromLang } from "helpers";

export const useFinalizeBooking = () => {
    const { system } = useParams();
    const navigate = useNavigate();
    const { openReservationFailedModal } = useError();

    const finalizeBooking = async (state: State) => {
        const guid = localStorage.getItem(GUID_STORAGE_KEY);
        if (!guid) return;
        const data: CreateReservationParameters = {
            ...state,
            lang: getLocaleFromLang(i18n.language),
            logMessage: "Bokning skapad",
            message: state.contact?.message,
        };

        let eventName = "BookingFinalized";
        let booking: WebBooking;

        const startTime = new Date().getTime();
        try {
            booking = await post<WebBooking, CreateReservationParameters>(
                `WebBooking/WebBookings/${guid}/Finalize`,
                data
            );
            queryClient.setQueryData<WebBooking>(["booking", guid], new WebBooking(booking));
            localStorage.removeItem(GUID_STORAGE_KEY);

            navigate(`/booking/${system}/${guid}`, {
                state: { isFinal: true },
            });

            document.documentElement.scrollTop = 0;
            document.body.scrollTop = 0;
            window.parent.postMessage("scroll2top", "*");
        } catch (error) {
            eventName = "BookingFinalizeFailed";
            openReservationFailedModal();
        }

        const endTime = new Date().getTime();
        const loadTime = endTime - startTime;
        trackEvent(eventName, {
            guests: state.guests,
            children: state.guestsChildren,
            date: state.times[0].start.toISOString(),
            isWaitingList: state.times.length > 1,
            ms: loadTime,
        });

        return booking;
    };

    return useMutation({
        mutationFn: finalizeBooking,
        scope: {
            id: "webbooking-create",
        },
    });
};
