import { Button } from "caspeco-casper-ui";
import { useReservation, useSelectedRule } from "hooks";
import { useTranslation } from "react-i18next";
import { useBookingStateContext } from "store";
import { PaymentRule } from "types/payment";
import { getPaymentCalculationTotal } from "utils/payment-utils";

interface ISubmitButtonProps {
    isDisabled: boolean;
    isLoading: boolean;
}

const SubmitButton = ({ isDisabled, isLoading }: ISubmitButtonProps) => {
    const { t } = useTranslation();
    const { isWaitList } = useBookingStateContext();
    const selectedRule = useSelectedRule();
    const state = useBookingStateContext();
    const reservation = useReservation();

    if (!selectedRule) return null;

    const getButtonText = () => {
        if (isWaitList) return t("waitList.signUpWaitList");
        switch (selectedRule?.paymentRule) {
            case PaymentRule.NoShowFee:
                return t("registerPaymentCard");
            case PaymentRule.Prepayment: {
                const totalValue = getPaymentCalculationTotal(
                    selectedRule,
                    state.guests,
                    reservation.data?.articles?.filter((x) => x.isTable)
                );
                return t("payAmount", {
                    amount: t("currency.onlyAmount", {
                        value: totalValue,
                    }),
                });
            }
            default:
                return t("book");
        }
    };
    return (
        <Button
            size="lg"
            variant="primary"
            type="submit"
            isLoading={isLoading}
            isDisabled={isDisabled}
            w="100%"
        >
            {getButtonText()}
        </Button>
    );
};

export default SubmitButton;
