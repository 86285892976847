import { parseDateWithTimeZone } from "utils";

export enum PaymentRule {
    NoFee = 0,
    Prepayment = 1,
    NoShowFee = 2,
    NoShowFeeReservation = 3,
    WebShop = 4,
}
export enum PaymentCalculationRule {
    NoPayment = 0,
    PaymentPerGuest = 1,
    PaymentPerBooking = 2,
    PaymentPerResource = 3,
}

export enum PaymentMethod {
    CreditCard = 0,
    Invoice = 1,
    Mobile = 6,
    Reepay = 7,
    Undefined = 999,
}
export class PaymentEvent {
    id: number;
    type: PaymentEventType;
    transactionDate: IMoment;
    payedAmount: number;
    requestedAmount: number;

    constructor(values?: PaymentEvent) {
        this.id = values?.id ?? 0;
        this.type = values?.type ?? PaymentEventType.WebBookingCharge;
        this.transactionDate = parseDateWithTimeZone(values?.transactionDate, true);
        this.payedAmount = values?.payedAmount ?? 0;
        this.requestedAmount = values?.requestedAmount ?? 0;
    }
}

export enum PaymentEventType {
    WebBookingCharge = 6,
    PaymentLinkCharge = 7,
    NoShow = 9,
    AddedPaidAmountLog = 13,
    NoShowLink = 14,
}

export enum ChargeStatusEnum {
    Unknown = 0,
    Created = 102,
    CardRegistered = 103,
    PaymentExpired = 113,
    PaymentFailed = 114,
    PaymentRefundFailed = 115,
    InactivatedByUser = 116,
}
