interface IEnvironment {
    baseUrl: string;
    environment: Environment | null;
    isDev: boolean;
}
export enum Environment {
    prod = "prod",
    dev = "dev",
    local = "local",
    devLocal = "dev-local",
    stage = "stage",
}

function getEnvironment(name: string): Environment | null {
    switch (name) {
        case "prod":
            return Environment.prod;
        case "dev":
            return Environment.dev;
        case "dev-local":
            return Environment.devLocal;
        case "stage":
            return Environment.stage;
        case "local":
            return Environment.local;
        default:
            return null;
    }
}

function isDev(env: Environment): boolean {
    switch (env) {
        case Environment.dev:
        case Environment.local:
        case Environment.devLocal:
            return true;
        default:
            return false;
    }
}

const environment = getEnvironment(import.meta.env.VITE_APP_ENV_NAME ?? "");
export const config: IEnvironment = {
    baseUrl: localStorage.getItem("BASE_URL") ?? import.meta.env.VITE_APP_BASE_URL ?? "",
    environment,
    isDev: isDev(environment),
};

export default config;
