// import i18n from "config/i18n";
import { t } from "i18next";
import { State, Time } from "store";
import {
    Contact,
    Customer,
    PaymentMethod,
    ValidationResult,
    WaitListOffer,
    WaitListOfferStatus,
    WebBooking,
} from "types";
// import { getLocaleFromLang } from "helpers";

class disableConfirmationParameters {
    disableAllConfirmationMessages: boolean;
    disableEmailConfirmationMessage: boolean;
    disableSmsConfirmationMessage: boolean;
    constructor(values?: Partial<disableConfirmationParameters>) {
        this.disableAllConfirmationMessages = values?.disableAllConfirmationMessages ?? false;
        this.disableEmailConfirmationMessage = values?.disableEmailConfirmationMessage ?? false;
        this.disableSmsConfirmationMessage = values?.disableSmsConfirmationMessage ?? false;
    }
}

export class WaitListConfirmParameters extends disableConfirmationParameters {
    startTime: string;
    constructor(waitListOffers: WaitListOffer[], values?: Partial<disableConfirmationParameters>) {
        super(values);
        this.startTime = waitListOffers
            ?.find((offer) => offer.offerStatus === WaitListOfferStatus.Available)
            ?.startTime.format("YYYY-MM-DD[T]HH:mm:ss");
    }
}

export class CancelParameters extends disableConfirmationParameters {
    logMessage: string;
    constructor(values?: Partial<CancelParameters>) {
        super(values);
        this.logMessage = values?.logMessage ?? "";
    }
}

export class CreateReservationParameters {
    lang?: string;
    isPending?: boolean;
    logMessage?: string;
    message?: string;
    timeSetDate?: string;
    isWaitList?: boolean;
    times?: { ruleId: number; start: string }[];
    guests?: number;
    guestsChildren?: number;

    constructor(state?: State, logMessage?: string) {
        this.isPending = true;
        this.lang = "sv";
        /* Override msg or default message. */
        this.logMessage = logMessage ?? t("changeLogMessage.pendingLogMessage");
        this.message = state?.contact?.message ?? "";

        this.guests = state?.guests;
        this.guestsChildren = state?.guestsChildren ?? 0;
        this.isWaitList = state?.isWaitList;

        /* TimeSetDate = the selected booking date */
        this.timeSetDate = state?.date.format("YYYY-MM-DD[T]HH:mm:ss");
        this.times =
            state?.times.map((time: Time) => {
                return {
                    ruleId: time.ruleId,
                    start: time.start.format("YYYY-MM-DD[T]HH:mm:ss"), // Throw away timezone
                };
            }) ?? [];
    }
}

export class PaymentTerminalParameters {
    terminalReturnUrl: string;
    payMethod: PaymentMethod;
    contact: Contact | null;
    customer: Customer | null;
    message: string;
    // z: string;
    // invoiceMarking: "",

    constructor(returnUrl: string, state?: State, webBooking?: WebBooking) {
        this.terminalReturnUrl = returnUrl ?? "";
        this.payMethod = state?.payment.payMethod ?? PaymentMethod.CreditCard;
        this.contact = state?.contact ?? webBooking?.contact ?? null;
        this.customer = state?.customer ?? webBooking?.customer ?? null;
        this.message = state?.contact?.message ?? "";
    }
}

export interface BackFromPaymentTerminalParameters {
    bookingChargeId: string;
    startTime: string | null;
}
export class BackFromTerminalResponse {
    result: WebBooking | null;
    validationResult: ValidationResult;

    constructor(values: BackFromTerminalResponse) {
        this.result = values.result ?? null;
        this.validationResult =
            new ValidationResult(values.validationResult) ?? new ValidationResult();
    }
}
