import { Flex, Icons, ThemeSpaceVariable } from "caspeco-casper-ui";
import { useExternalBookingSettings } from "hooks";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useBookingActionsContext, useBookingStateContext } from "store";
import { useDateDisplay, useTimeText } from "utils";
import EditSelectionButton from "./edit-selection-button";
import { setSearch } from "search-params";

export const EditSelectionButtonGroup = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { maxWebGuests: maxGuests } = useExternalBookingSettings().data.unitsMetaData;
    const { guests, guestsChildren, date, times } = useBookingStateContext();
    const { setState, handleCancel } = useBookingActionsContext();

    const timeText = useTimeText(times, date);
    const { dateText } = useDateDisplay(date);

    const tooManyGuests = maxGuests && guests && guests > maxGuests;

    let guestText = t("start.numberGuests", { guests });
    if (guestsChildren > 0) {
        guestText += ` (${t("start.ofWhich", { children: guestsChildren })})`;
    }

    const setMultipleSearch = (keys: string[]) => {
        keys.forEach((key) => setSearch(navigate, key));
    };

    const resetGuests = () => {
        if (times?.length > 0) handleCancel();

        setState((prevState) => ({
            ...prevState,
            guests: null,
            guestsChildren: null,
            date: null,
            times: null,
            isWaitList: false,
        }));

        setMultipleSearch(["guests", "children", "date", "r", "start", "end"]);
    };

    const resetDate = () => {
        if (times?.length > 0) handleCancel();

        setState((prevState) => ({
            ...prevState,
            date: null,
            times: null,
            isWaitList: false,
        }));
        setMultipleSearch(["date", "r", "start", "end"]);
    };

    const resetTime = () => {
        if (times?.length > 0) handleCancel();

        setState((prevState) => ({
            ...prevState,
            times: null,
            isWaitList: false,
        }));
        setMultipleSearch(["r", "start", "end"]);
    };

    return (
        <Flex flexDirection="column" gap={ThemeSpaceVariable.Small}>
            {guests && !tooManyGuests && (
                <EditSelectionButton
                    icon={Icons.UserGroup}
                    text={guestText}
                    onClick={resetGuests}
                />
            )}
            {date && <EditSelectionButton icon={Icons.Date} text={dateText} onClick={resetDate} />}
            {timeText && (
                <EditSelectionButton icon={Icons.Time} text={timeText} onClick={resetTime} />
            )}
        </Flex>
    );
};
