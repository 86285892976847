import { useMutation } from "@tanstack/react-query";
import { post } from "api/api-methods";
import { useError, WebBookingErrorCode } from "context";
import { useNavigate, useParams } from "react-router-dom";
import { GUID_STORAGE_KEY } from "store";
import {
    BackFromPaymentTerminalParameters,
    BackFromTerminalResponse,
    ValidationResult,
} from "types";
import { getSearchParams } from "search-params";

export const useBackFromPaymentTerminal = () => {
    const { system } = useParams();
    const navigate = useNavigate();
    const { setErrorCode, setValidationResult } = useError();

    const backFromPaymentTerminal = async ({
        chargeId,
        offerStartTime,
    }: {
        chargeId: string;
        offerStartTime: string | null;
    }) => {
        const guid = localStorage.getItem(GUID_STORAGE_KEY);
        const data: BackFromPaymentTerminalParameters = {
            bookingChargeId: chargeId,
            startTime: offerStartTime,
        };

        // const eventName = "BackFromPaymentTerminal";
        let response: BackFromTerminalResponse;

        // const startTime = new Date().getTime();

        try {
            response = await post<BackFromTerminalResponse, BackFromPaymentTerminalParameters>(
                `WebBooking/WebBookings/${guid}/BackFromPaymentTerminal`,
                data
            );
        } catch (error) {
            const { webBookingId, offerStartTime } = getSearchParams();
            const response = error.response?.data as BackFromTerminalResponse;
            const validationResult = new ValidationResult(response?.validationResult);
            const isWaitList = offerStartTime;
            if (validationResult.isDoubleBookingException() && isWaitList) {
                const urlDbe = `/booking/${system}/${webBookingId}`;
                navigate(urlDbe, {
                    state: { failedPaymentConfirmWaitList: true },
                });
            }
            // If request fails - set generic payment error code
            setErrorCode(WebBookingErrorCode.PaymentError);
        }

        return response;
    };

    return useMutation({
        mutationFn: backFromPaymentTerminal,
        scope: {
            id: "webbooking-create",
        },
        onSuccess(response) {
            const validationResult = new ValidationResult(response?.validationResult);
            if (response && validationResult.isValid()) {
                const guid = localStorage.getItem(GUID_STORAGE_KEY);
                localStorage.removeItem(GUID_STORAGE_KEY);
                navigate(`/booking/${system}/${guid}`, {
                    state: { isFinal: true },
                });
            } else {
                const validationResult = new ValidationResult(response?.validationResult);
                setValidationResult(validationResult);
                // If request fails - set generic payment error code
                setErrorCode(WebBookingErrorCode.PaymentError);
            }
        },
    });
};
