// External
import { List, Set } from "immutable";
// Models
import { RecordFactory } from "../common/modules/recordFactory";
import { Section } from "./section";
import { UnitMetaDataExternal } from "./unitMetaDataExternal";
import { PaymentMethod } from "./webBookingPaymentTerminalParameters";
import { WhiteLabelConfig } from "./whiteLabel/whiteLabelConfig";

export enum BookingWindowUnit {
    Months,
    Weeks,
    Days,
}

export interface IExternalBookingSettings {
    minGuests: number;
    maxGuests: number;
    openingTime: number;
    closingTime: number;
    companyName: string;
    genericSignature: string;
    maxGuestsInfo: string;
    defaultAmountGuests?: number;
    phoneNumber: string;
    email: string;
    sections: List<Section>;
    defaultExpandedSectionIds: Set<number>;
    unitsMetaData: UnitMetaDataExternal;
    messageDescription: string;
    messagePUL: string;
    messageBookingClosed: string;
    webConditions: string;
    menuHeader: string;
    menuMessage: string;
    displayMessageBox: boolean;
    displayMenuMessageBox: boolean;
    menuMessageDescription: string;
    gdprInfo: string;
    lastGrab: boolean;
    newsletter: boolean;
    gaTrackingId: string;
    bookingWindowAsMonth: number;
    bookingWindowUnit: BookingWindowUnit;
    paymentMethods: List<PaymentMethod> | null;
    displayCompanyName: boolean;
    enableInvoicing: boolean;
    deliveryInfoDescription: string;
    themeSettings: WhiteLabelConfig;
    address: string;
    disableCancel: boolean;
    introductionMessageHeader: string;
    introductionMessage: string;
}

const ExternalBookingSettingsRecord = RecordFactory<IExternalBookingSettings>({
    minGuests: -1,
    maxGuests: -1,
    openingTime: -1,
    closingTime: -1,
    companyName: "",
    genericSignature: "",
    maxGuestsInfo: "",
    defaultAmountGuests: undefined,
    phoneNumber: "",
    email: "",
    sections: List<Section>(),
    defaultExpandedSectionIds: Set<number>(),
    unitsMetaData: new UnitMetaDataExternal({}),
    messageDescription: "",
    messagePUL: "",
    messageBookingClosed: "",
    webConditions: "",
    menuHeader: "",
    menuMessage: "",
    displayMessageBox: true,
    gdprInfo: "",
    lastGrab: false,
    newsletter: false,
    gaTrackingId: "",
    bookingWindowAsMonth: 0,
    bookingWindowUnit: BookingWindowUnit.Months,
    paymentMethods: null,
    displayMenuMessageBox: true,
    menuMessageDescription: "",
    displayCompanyName: false,
    enableInvoicing: false,
    deliveryInfoDescription: "",
    themeSettings: new WhiteLabelConfig({}),
    address: "",
    disableCancel: false,
    introductionMessageHeader: "",
    introductionMessage: "",
});

export class ExternalBookingSettings
    extends ExternalBookingSettingsRecord
    implements IExternalBookingSettings
{
    constructor(values?: Partial<IExternalBookingSettings>) {
        if (values) {
            if (values.sections) {
                values.sections = List<Section>(values.sections);
            }

            if (values.defaultExpandedSectionIds) {
                values.defaultExpandedSectionIds = Set<number>(values.defaultExpandedSectionIds);
            }

            if (values.unitsMetaData) {
                values.unitsMetaData = new UnitMetaDataExternal(values.unitsMetaData);
            }

            if (values.paymentMethods) {
                values.paymentMethods = List<PaymentMethod>(values.paymentMethods);
            }
            if (values.themeSettings) {
                values.themeSettings = new WhiteLabelConfig(values.themeSettings);
            }
        }

        super(values);
    }
}
